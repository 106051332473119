<template>
  <default-layout>
    <v-container fluid tag="section">
      <page-loading :show="loading || loadingForm" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <agent-detail-form
        :permissions="permissions"
        :allowedActions="allowedActions"
        style="width: 100%"
      ></agent-detail-form>
    </v-container>
    <modal-agent></modal-agent>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const AgentDetailForm = () =>
  import('@/components/user-management/beliruma-user/agent-detail-form');
// const ListingList = () => import('@/components/data-entry/listing/list');
const ModalAgent = () => import('@/components/modals/modal-agent');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    AgentDetailForm,
    // ListingList,
    ModalAgent,
  },
  data() {
    return {
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.belirumaUser.loading,
      loadingForm: (state) => state.belirumaUser.form.loading,
      permissions: (state) => state.belirumaUser.permissions,
      allowedActions: (state) => state.belirumaUser.allowedActions,
    }),
  },
  async mounted() {
    await this.refreshAction();
  },
  methods: {
    async refreshAction() {
      await this.loadForm();
    },
    async loadForm() {
      try {
        this.$store.commit('belirumaUser/RESET_DEFAULT');
        this.$store.commit('belirumaUser/form/SET_FORM_TYPE', 'ADD');
        const response = await this.$store.dispatch('belirumaUser/getAddData');
        console.log('GET ADD DATA: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      }
    },
  },
};
</script>
